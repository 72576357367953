import React, {useState} from 'react';
import {Button, Row, Col, Form, Badge} from 'react-bootstrap';
import {useQuery,gql} from "@apollo/client";
import {compose} from 'redux';
import {usePortalContext} from '../../lib/context/portalContext';
import {useDeviceMutations} from '../devices/deviceMutations';
import withAlerts from '../../lib/withAlerts';
import {nullCheck} from '../../lib/testUUID';
import {NIL} from 'uuid';
import {useForm} from 'react-hook-form';

const PolicyAssignment = props => {
  const {success, error} = props.alerts;
  const {rowData, togglePortal} = usePortalContext();
  const [showAssignment, setShowAssignment] = useState(null);
  const {register, handleSubmit, getValues, watch, formState: {errors}} = useForm();
  let assignedPolicyId = rowData?.policy_id ?? rowData?.policy?.policy_id ?? null;
  const {product_type: {greyhound_family}} = rowData || false;
  const pID = nullCheck(assignedPolicyId);
  const {data: pData, loading} = useQuery(POLICIES);
  let policies = pData?.Policies?.result ?? [];  
  
  const {setCriteria} = useDeviceMutations();
  let hasDevice = rowData?.serial_number ? true : false;
  let hasUser = rowData?.user?.id ? true : false;
  
  const assignmentSubmit = async (formData) => {
    let value = formData === 'unassign' ? NIL : formData.policy_selector;
    let msg = formData.policy_selector ? 'assigned' : 'unassigned';

    const setDeviceCriteria = await setCriteria({variables: 
      {
        serial_number: rowData?.serial_number,
        field: 'policy_id',
        value
      }
    });
    
    if(setDeviceCriteria.errors) {
      error({msg: 'There was an error assigning the policy. Please try again.'});
    } else {
      // Policy assigned / Policy unassigned
      success({msg: `Policy ${msg}.`});
    }
    togglePortal();  
  }
  
  return (
    <div className="offcanvas-form">
      
      <Form className="mb-3" onSubmit={handleSubmit(assignmentSubmit)}>
        {!hasDevice || !hasUser && 
          <Row>
            <Col>
              <p>Device must be paired to user before policy can be assigned.</p>   
            </Col>
          </Row> 
        }
        {pID &&
        <>
          <Row>
            <Col>
              <Form.Group className="mb-3">
                <Form.Label>Policy Name</Form.Label>
                <div>{rowData?.policy?.name}</div>
              </Form.Group>
              
              <Form.Group className="mb-3 rules-pills">
                <Form.Label>Policy Rules</Form.Label>
                {rowData?.policy?.has_battery_warning &&  
                <Row>
                  <Col className="mb-3">
                    <Badge pill bg="none">Battery Level Sufficient {rowData?.policy?.battery_warning_threshold}%</Badge>   
                  </Col>
                </Row>
                }

                {rowData?.policy?.is_phone_protected &&
                  <Row>
                    <Col className="mb-3">
                      <Badge pill bg="none">Protected Mode Engaged</Badge>
                    </Col>
                  </Row>         
                }
                {rowData?.policy?.is_cellular_connected &&
                  <Row>
                    <Col className="mb-3">
                      <Badge pill bg="none">Cellular Radio Off</Badge>
                    </Col>
                  </Row>         
                }
              </Form.Group>
            </Col>
          </Row>
          <Row className="mb-3">
            <Col>
              <span className="link-span privoro-blue" onClick={() => setShowAssignment(true)}>Change Assigned Policy</span>
            </Col>
          </Row>
          <Row className="mb-3">
            <Col>
              <span className="link-span" onClick={() => assignmentSubmit('unassign')}>Unassign Policy</span>
            </Col>
          </Row>
        </>
        }

        {!pID || showAssignment ?
        <>
          {greyhound_family &&
          <Row>
            <Col>
              <p><strong>Notice: </strong> SafeCase ONX does not support policy application & monitoring. For further information and assistance, please reach out to your designated Privoro representative.</p>
            </Col>
          </Row>
          }
          <Row>
            <Col className="mb-3">
              <Form.Label>Assign Policy</Form.Label>
              <Form.Select {...register('policy_selector')} disabled={ (!hasDevice || !hasUser || greyhound_family) }>
                <option>Select policy</option>
                {policies.map(({id, name}, i) => (
                  <option key={i} value={id}>{name}</option>
                ))}
              </Form.Select>
            </Col>
          </Row>

          <Row>
            <Col>
              <div className="d-grid gap-2">
                <Button type="submit" disabled={greyhound_family || !hasDevice || watch('policy_selector') === undefined || watch('policy_selector') === 'Select policy'}>Assign Policy</Button>
              </div>
            </Col>
          </Row>
          </>
        : null }
      </Form>      
    </div>
  )
}

const POLICIES = gql`
  query getPolicies {
    Policies {
      result {
        id
        name
        geofence_group_id
        description
        is_active
        is_delete
        is_phone_protected
        is_offline
        has_battery_warning
        battery_warning_threshold
        is_checked_in
        is_camera_exposed
        is_cellular_connected
        is_microphone_exposed
        is_phone_present
        policy_action_id
        created_at
        modified_at
      }      
      metadata {
        page {
          num
          len
          total
          inPage
        }
      }
    }
  }
`;

export default compose(
  withAlerts
)(PolicyAssignment);
