import {gql, useMutation} from '@apollo/client';

const ASSIGN_ROLE = gql`
  mutation ($role_input: RoleInput) {
    AssignRole(role_input: $role_input)
  }
`;

const UNASSIGN_ROLE = gql`
  mutation ($role_input: RoleInput) {
    UnassignRole(role_input: $role_input)
  }
`;

export function useCustomerAdminRoleMutations () {

  const [assign] = useMutation(ASSIGN_ROLE,
    { refetchQueries: ['getAdmins'] },
  );

  const [unassign] = useMutation(UNASSIGN_ROLE,
    { refetchQueries: ['getAdmins'] },
  );
  return { assign, unassign };
}
