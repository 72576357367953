import React, { useState, useEffect } from 'react';
import {Row,Col} from 'react-bootstrap';
import Moment from 'react-moment';
import {getAuthStore} from './auth/sessionStore';
import {compose} from 'redux';
import withAlerts from '../lib/withAlerts';
import {useRoleCheck} from '../lib/roleCheck';


const PageHeader = (props) => {
  const {claims} = getAuthStore();
  const {preferred_username: username} = claims || {};
  const [titleUsername, setTitleUsername] = useState('');
  const {alerts} = props;
  const {info} = alerts;
  const hasRole = useRoleCheck(username);
  
  useEffect(()=>{
    if(username) {
      setTitleUsername(username);
    }
    
    //Checks to see if assigned role count >= 1
    if(!hasRole && hasRole !== undefined) {
      info({msg: "Contact administrator for PFC permissions."})
    }
  }, [username, hasRole]);

  return (
    <div id="page-header" data-testid="page-header" className="p-3 sticky-top">
      <Row className="justify-content-end">
        <Col sm="auto" className="align-self-center">
          <Moment data-testid="timestamp" interval={1000} utc format="MMM DD, YYYY HH:mm:ss"/> UTC
        </Col>
        <Col sm="auto" className="align-self-center username ms-3">
          {titleUsername}
        </Col>
      </Row>  
    </div>
  );
};

export default compose(withAlerts)(PageHeader);
