import {useRef, useState} from 'react';
import {Button, Row, Col, Form} from 'react-bootstrap';
import {usePortalContext} from '../../lib/context/portalContext';
import withAlerts from '../../lib/withAlerts';
import {compose} from 'redux';
import {useQuery, gql } from "@apollo/client";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCheck, faXmark} from '@fortawesome/pro-solid-svg-icons';
import dateFormat from '../../lib/dateFormat';
import cx from 'classnames';
import {useForm} from 'react-hook-form';
import {useWhitelistMutations} from '../whitelist/whitelistMutations';

const WhitelistDetails = (props) => {
  const {register, handleSubmit, formState: { errors } } = useForm();
  const {rowData, togglePortal} = usePortalContext();
  const {assign, unassign} = useWhitelistMutations();
  const config = rowData?.device_configuration ?? {};
  const inputRef = useRef();  
  const {whitelist_ssid_list_id, id} = rowData ?? null;
  const {data} = useQuery(WHITELISTS);
  const whitelists = data?.Whitelists?.result || [];
  const [disabled, setDisabled] = useState(true);
  const {success, error} = props.alerts;
  
  const existingWhitelist = whitelists?.find(({id}) => id === whitelist_ssid_list_id) ?? null;
  const [currentWhitelist, setCurrentWhitelist] = useState(existingWhitelist);
  const [showButton, setShowButton] = useState(!existingWhitelist || false);

  const handleWhitelistAssignment = async (formData) => {
    let list_id = formData?.whitelist_ssid_list_id;

    if(list_id === "select_whitelist" || list_id == undefined) {
      return null;  
    }

    const assignDevice = await assign({
      variables: {
        whitelist: {
          whitelist_ssid_list_id: `${list_id}`,
          devices: JSON.stringify([`${id}`]) 
        }
      }          
    })

    if(assignDevice?.errors) {
      let userFacingError = ('SSID Whitelist assignment failed.');
      console.error(userFacingError + JSON.stringify(assignDevice?.errors));
      error({msg: userFacingError});
    } else {
      setCurrentWhitelist(whitelists?.find((l) => l.id == list_id) || {});
      success({msg: `SSID Whitelist assigned.`});
      setShowButton(false);
      togglePortal();
    }
    
  };

  const unassignWhitelist = async () => {
    const unassignDevice = await unassign({
      variables: {
        whitelist: {
          devices: JSON.stringify([`${id}`])  
        }          
      }
    })

    if(unassignDevice?.errors) {
      let userFacingError = ('SSID Whitelist unassignment failed.');
      console.error(userFacingError + JSON.stringify(unassignDevice?.errors));
      error({msg: userFacingError});
    } else {
      setCurrentWhitelist(false);
      success({msg: `Whitelist unassigned.`});
      setShowButton(false);
      togglePortal();
    }
  }

  const handleButton = (v) => {(
    v != whitelist_ssid_list_id && 
    v != 'select_whitelist') ? 
    setDisabled(false) : 
    setDisabled(true)
  }
  
  return ( <>
    <div className="offcanvas-form" data-testid="offcanvas-safecase">
      <Form className="mb-3" onSubmit={handleSubmit(handleWhitelistAssignment)}>
        {currentWhitelist && 
          <Row>
            <Col className="mb-3">
              <Form.Group>
                <Form.Label>SSID Whitelist Name</Form.Label>
                <div>{currentWhitelist?.name}</div>
              </Form.Group>
            </Col>
          </Row>
        }

        <Row className={cx({'d-none': !showButton})}>
          <Col className="mb-3">
            {currentWhitelist && <Form.Label>Change Assigned Whitelist</Form.Label>}
            {!currentWhitelist && <Form.Label>Assign Whitelist</Form.Label>}
            <Form.Select
              ref={inputRef}
              {...register("whitelist_ssid_list_id")}
              disabled={!showButton}
              onChange={e => handleButton(e.target.value)}
            > 
              <option key="select_whitelist" value="select_whitelist">Select Whitelist</option>
              {/* {currentWhitelist && <option key="unassign_whitelist" value="unassign_whitelist">Unassign Whitelist</option>} */}
              {whitelists && whitelists.map(({id, name}, i) => (
                <option key={id} disabled={whitelist_ssid_list_id == id} value={id}>{name}</option>
              ))}
            </Form.Select>
          </Col>
        </Row>

        <Row className={cx({'d-none': !showButton})}>
          <Col className="mb-3">
            <div className="d-grid gap-2">
              <Button disabled={disabled} type="submit">Assign SSID Whitelist</Button>
            </div>
          </Col>
        </Row>

        {/* <Row className="mb-3" hidden={!showButton}> */}
        {/*   <Col> */}
        {/*     <a className="link-span privoro-gray" onKeyDown={(e) => setShowButton(false)} onClick={(e) => setShowButton(false)}>Cancel Edit</a> */}
        {/*   </Col> */}
        {/* </Row> */}
        
        <Row className={cx('mb-3', {'d-none': showButton})}>
          <Col>
            <a 
              onKeyDown={() => setShowButton(!showButton)} 
              onClick={() => setShowButton(!showButton)} 
              className={cx({'d-none': showButton}, 'link-span privoro-blue' )}>
              Change Assigned SSID Whitelist
            </a>
          </Col>
        </Row>
        <Row className={cx('mb-3', {'d-none': showButton})}>
          <Col>
            <span
              role="button"
              onKeyDown={() => unassignWhitelist()} 
              onClick={() => unassignWhitelist()} 
              className={cx({'d-none': showButton}, 'link-span' )}>
              Unassign SSID Whitelist
            </span>
          </Col>
        </Row>
      </Form>
    </div>
    </> )
}

const WHITELISTS = gql`
  query getWhitelists {
    Whitelists {
      result {
        id
        name
        created_at
        modified_at
      }
      metadata {
        page {
          num
          len
          total
          inPage
          records
        }
      }
    }
  }
`;

export default compose(
  withAlerts,
)(WhitelistDetails);
