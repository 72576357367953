import {useState} from 'react';
import { Button, Row, Col, Form } from 'react-bootstrap';
import { compose } from 'redux';
import { usePortalContext } from '../../lib/context/portalContext';
import withAlerts from '../../lib/withAlerts';
import { useCustomerAdminRoleMutations } from './adminMutations'
import { gql, useQuery } from "@apollo/client";
import {useForm} from 'react-hook-form';
import {PropTypes} from 'prop-types';
import {getAuthStore} from '../auth/sessionStore';

const AdminDetails = (props) => {
  const {togglePortal, rowData} = usePortalContext();
  const {assign, unassign} = useCustomerAdminRoleMutations();
  const {data} = useQuery(ROLES);
  const [buttonText, setButtonText] = useState('Assign Role');
  const {register, handleSubmit } = useForm();
  let {alerts} = props;
  let {success, error} = alerts;
  const roles = data?.Roles?.result || [];
  const {id: admin_id, username} = rowData?.customer_admin ?? '';
  const role_name = rowData?.role_names[0] ?? '';

  const existingRole = roles.find(role => role.name === role_name) || 'select_role';

  // get current username
  const {claims} = getAuthStore();
  const {email: current_username} = claims || {};

  const handleRoleUnassign = async (role_input) => {
    let unassignRole = await unassign({variables: {role_input: role_input}});
    return unassignRole;
  }

  const handleRoleAssign = async (role_input) => {
    let assignRole = await assign({variables: {role_input: role_input}});
    return assignRole;
  }

  const handleRoleChange = async (formData) => {
    let role_id = formData?.role;
    let handler, subHandler;

    switch(role_id) {
      case 'select_role':
        return null;

      case undefined:
        return null;

      case 'unassign':
        if(existingRole?.id) {
          handler = await handleRoleUnassign({
            id: existingRole.id,
            admin_id: admin_id
          });
        }
        break;
      
      default:
        if(existingRole?.id) {
          await handleRoleUnassign({
            id: existingRole.id,
            admin_id: admin_id
          });
        }

        handler = await handleRoleAssign({
          id: role_id,
          admin_id: admin_id
        })
        break;
    }

    if(handler?.errors) {
      let userFacingError = ('Administrator role assignment failed.');
      console.error(userFacingError + JSON.stringify(handler?.errors));
      error({msg: userFacingError});  
    } else {
      success({msg: `Administrator role assignment completed.`});
      togglePortal();
    }
  }

  const handleRoleSelect = (val) => {
    if(val == 'unassign') {
      setButtonText("Unassign Role");
    } else {
      setButtonText("Assign Role");
    }
  }


  return (
    <div className="offcanvas-form">
      <Form className="mb-3" onSubmit={handleSubmit(handleRoleChange)}>
        <Form.Group className="mb-3">
          <Form.Label>Administrator ID</Form.Label>
          <Form.Control type="text" value={username} readOnly={true} disabled/>
        </Form.Group>
        

        <Form.Group className="mb-3">
          <Form.Label>Role</Form.Label>
            <Form.Select
              disabled={username === current_username}
              {...register('role',{
                value: existingRole?.id,
                onChange: (e) => handleRoleSelect(e.target.value)
              })}
            >
              <option key="select_role" value="select_role">Select Role</option>
              {roles?.map(({id, name}) => (
                <option key={id} value={id}>{name}</option>
              ))}
              <option key="unassignrole" value="unassign">Unassign role</option>
            </Form.Select>
          </Form.Group>

          <Row>
          <Col className="mb-3">
            <div className="d-grid gap-2">
              <Button type="submit" disabled={username === current_username}>{buttonText}</Button>
            </div>
          </Col>
        </Row>
      </Form>
    </div>
  );
}

const ASSIGNEDROLES = gql`
  query getRoleByAdminID($customer_admin_id: String!) {
    RoleByAdminID(customer_admin_id: $customer_admin_id) {
      result {
        id
        type
        name
      }
    }
  }  
`;

const ROLES = gql`
  query getRoles {
    Roles {
      result {
        id
        type
        name
        description        
      }
      metadata {
        page {
          num
          len
          total
          inPage
          records
        }
      }
    }
  }
`;

AdminDetails.propTypes = {
  alerts: PropTypes.shape({
    success: PropTypes.func,
    error: PropTypes.func
  })
}

//export default AdminDetails
export default compose(
  withAlerts
)(AdminDetails);
