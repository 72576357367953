import {useState, useEffect} from 'react';
import {useQuery,gql} from "@apollo/client";
import Table from '../table';
import ActionBar from '../actionBar';
import Portal from '../portal';
import AdminDetails from './adminDetail';
import {useRecoilValue} from 'recoil';
import {pageSizeRecoil} from '../recoil';

export const AdminUsers = props => {
  const {data, loading} = useQuery(ADMINS);
  const [pageNum, setPageNum] = useState(1);
  const [totalRecordsState, setTotalRecordsState] = useState(0);
  const pageSize = useRecoilValue(pageSizeRecoil);

  let parseResult = data?.Admins && JSON.parse(data.Admins);
  let admins = parseResult?.result || [];

  // if this doesn't come back (fail case), it's safer to let them page the first 100*10 per
  let totalQueried = admins?.length ?? totalRecordsState;

  useEffect(()=> {
    setTotalRecordsState(totalQueried);
  }, [totalQueried]);

  const columns = [
    {
      dataField: 'customer_admin.id',
      hidden: true
    },
    {
      dataField: 'customer_admin.username',
      text: 'Administrator ID'
    },
    {
      dataField: 'role_names',
      text: 'Role'
    }
  ];
  
  return (
    <>
      <Portal title="Administrator">
        <AdminDetails />
      </Portal>
      <ActionBar showHidden={false} showSearchBar={false} title="Administrators" />
      <Table
        testId="admin-users-table"
        columns={columns}
        data={admins}
        loading={loading}
        pageSize={pageSize}
        pageNum={pageNum}
        setPageNum={setPageNum}
        totalRecords={totalRecordsState}
      />
    </>
  );
};

const ADMINS = gql`
query getAdmins {
  Admins
}
`;

export default AdminUsers;
