import {useState} from 'react';
import PropTypes from 'prop-types';
import {Row, Col, Form, Button} from 'react-bootstrap';
import {compose} from 'redux';
import {usePortalContext} from '../../lib/context/portalContext';
import withAlerts from '../../lib/withAlerts';
import DeleteConfirmModal from '../deleteConfirmModal';
import WhitelistConfirmModal from './confirmWhitelistModal';
import {useWhitelistMutations} from './whitelistMutations';
import {useForm} from 'react-hook-form';
import {FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faXmark} from '@fortawesome/pro-light-svg-icons';
import { useQuery, useLazyQuery, gql } from "@apollo/client";
import cx from 'classnames';

const EditWhitelist = props => {
  const {register, reset, handleSubmit, watch, setValue, setFocus, formState: {errors} } = useForm();
  const {rowData, togglePortal} = usePortalContext();
  const {success, error} = props.alerts;
  const {remove, edit} = useWhitelistMutations();
  const [editing, setEditing] = useState(false);
  const [duplicateName, setDuplicateName] = useState(false);
  const [getWhitelistNames] = useLazyQuery(WHITELISTS);

  // const handleCancel = () => {
  //   reset();
  //   setEditing(false);
  //   setDuplicateName(false);
  // }

  const {data, loading} = useQuery(SSIDS, {
    variables: {
      filters: {
        field: "whitelist_ssid_list_id", value: `${rowData?.id}`
      }
    },
    skip: !rowData?.id
  });

  let whitelistResult = data?.SSIDs?.result || [];
  
  const handleRemoveWhitelist = async () => {

    const removeWhitelist = await remove({
      variables: {
        whitelist_ssid_list_id: rowData?.id
      }
    });

    if(removeWhitelist?.errors) {

      let userFacingError = ('SSID Whitelist deletion failed.');
      console.error(userFacingError + JSON.stringify(removeWhitelist?.errors));
      error({msg: userFacingError});
      
    } else {
      togglePortal();
      success({msg: 'SSID Whitelist deleted.'});
      
    }
  };

  const nameCheck = async (name) => {
    
    if(rowData?.name === name || name === '') {
      return;
    }

    let check = await getWhitelistNames({
      variables: {
        filters: {
          field: "name",
          value: name
        }
      }
    })
    
    if(check?.data?.Whitelists) {
      setDuplicateName(true);
      setFocus('name')
      return;
    } else {
      setDuplicateName(false);  
    }
  }

  const handleWhitelist = async (formData) => {
    let name = formData?.name || "";
    
    let filtered = JSON.stringify(formData?.whitelist?.filter((ssid) => {
        return ssid != null && ssid != "";
    }));

    const whitelistInput = {
      name: name,
      whitelist_ssid_list_id: rowData?.id,
      SSIDs: filtered
    };

    const updateWhitelist = await edit({variables: {whitelist: whitelistInput}});
    if(updateWhitelist.errors) {

      console.error(JSON.stringify(updateWhitelist?.errors));
      error({msg: "Whitelist edit failed."});

    } else {
      success({msg: `Whitelist updated.`});
      reset();
      togglePortal();
    }
  }
  
  const buildList = () => {
    let fill = whitelistResult?.concat(new Array(8 - whitelistResult.length).fill(undefined));
    
    return (
      <>
        <Row>
          <Col>
            <Form.Group className="mb-5">
              <Form.Label>SSID Whitelist Name</Form.Label>
              <Form.Control
                {...register('name', {
                  value: rowData?.name,
                  onChange: e => nameCheck(e.target.value),
                  required: true
                })}
              type="text"
              className={cx({'error-input': duplicateName || errors.name})}
              disabled={!editing}/>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col>
          <Form.Label>Entries</Form.Label>
            { fill.map((item, i) => {
              return (
                <Row className="align-items-center mb-3" key={item?.id ?? i}>
                  <Col>
                    <Form.Group className="ssid-input">
                      <Form.Control
                        data-testid={`whitelist_${i}`}
                        id={`whitelist_${item?.id ?? i}`}
                        {...register(`whitelist.${i}`, {
                          value: item?.ssid,
                          onChange: e => watch()
                        })}
                        type="text"
                        placeholder="SSID..."
                        disabled={!editing}
                        />
                    </Form.Group>
                  </Col>
                  <Col lg="1" hidden={!editing}>
                    <FontAwesomeIcon
                      hidden={watch(`whitelist[${i}]`) == undefined}
                      onClick={e => setValue(`whitelist[${i}]`, undefined)}
                      icon={faXmark}
                      size="lg"
                      color="red"
                      className="pointer" />
                  </Col>
                </Row>
                );
            })}
          </Col>
        </Row>
      </>
    )
  }
  
  return (
    <div className="offcanvas-form">

      <Form>
        
        {data && buildList()}
        
        <Row>
          <Col>
            <div className="d-grid gap-2">
              
              {editing && <WhitelistConfirmModal onChange={handleSubmit(handleWhitelist)} />}
              
              {!editing && <Button className="mb-3" onClick={e => setEditing(true)}>Edit Whitelist</Button>}
            </div>
            
            {errors.name && errors.name.type === "required" &&
              <div className="mb-3">
                <strong className="privoro-black">SSID Whitelist name is required.<br/></strong>
              </div>
            }
            {duplicateName &&
              <div className="mb-3">
                <strong>SSID Whitelist name already exists.<br/></strong>
              </div>
            }
          </Col>
        </Row>
        {/* <Row className="mb-3" hidden={!editing}> */}
        {/*   <Col> */}
        {/*     <a className="link-span privoro-gray" onKeyDown={(e) => handleCancel()} onClick={(e) => handleCancel()}>Cancel Edit</a> */}
        {/*   </Col> */}
        {/* </Row> */}

        <Row className="mb-3">
          <Col>
            <DeleteConfirmModal
              onChange={handleRemoveWhitelist}
              scope={"ssid_whitelist"} 
              message={"Are you sure you want to delete this whitelist?"}
              title="SSID Whitelist" />
          </Col>
        </Row>
      </Form>
    </div>
  )
}

const SSIDS = gql`
  query getSSIDs($filters: GenericFilters!) {
    SSIDs(filters: $filters) {
      result {
        id
        ssid
      }
    }
  }
`;

const WHITELISTS = gql`
  query getWhitelists($filters: GenericFilters!) {
    Whitelists(filters: $filters) {
      result {
        name
      }
    }
  }
`;

EditWhitelist.propTypes = {
  alerts: PropTypes.shape({
    error: PropTypes.func,
    success: PropTypes.func
  })
}

export default compose(
  withAlerts,
)(EditWhitelist);
