import {Row, Col, Form} from 'react-bootstrap';
import {useQuery,gql} from "@apollo/client";
import {usePortalContext} from '../../lib/context/portalContext';
import {FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faSquare, faCheckSquare} from '@fortawesome/pro-light-svg-icons';
import {useForm} from "react-hook-form";
import cx from 'classnames';
import Tooltip from '../tooltip';

const RoleDetails = props => {
  const {rowData} = usePortalContext();
  const {name, description, id: role_id} = rowData ?? {};
  const {register} = useForm();
  const {data: rolePerms} = useQuery(ROLEPERMISSIONS, {
    variables: {
      filters: {
        field: "role_id",
        value: role_id
      }
    }
  });

  let activePermissions = rolePerms?.RolePermissions?.result || [];
  
  let activeKeys = activePermissions.reduce((acc,perm) => {
    acc.push(perm.permission_key);
    return acc;
  }, []);

  const {data} = useQuery(PERMISSIONS);
  let result = data?.Permissions?.result || [];
  const permsByCategory = Object.groupBy(result, (perm) => perm.category);

  let customLabel = (name, checked) => {
    return (
      <span className="custom-label">
        <FontAwesomeIcon icon={checked ? faCheckSquare : faSquare} size="lg" />{name}
      </span>
    )
  }

  return ( 
    <div className="offcanvas-form">
        <Form>
        <Row>
          <Col>
            <Form.Group className="mb-3">
              <Form.Label>Role Name</Form.Label>
              <Form.Control type="text" defaultValue={name} readOnly={true} disabled/>
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Description</Form.Label>
              <p>{description}</p>
            </Form.Group>

          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Group className="roles-pills">
              <Form.Label>Permissions</Form.Label>    
              <Row>
                <Col className="mb-3">
                  <div className="height-container">
                  {Object.entries(permsByCategory).map(([category, perms]) => {
                    return(
                      <>
                      <Form.Label className="mt-3">{category}</Form.Label>
                      
                      {perms.map(({key, display_name, description}) => {

                        let checked = activeKeys.includes(key) || false;
                        
                        return (
                          <Tooltip key={key} reverse placement="bottom-start" display="permission-tooltip" content={description}>
                            <Form.Check
                              readOnly
                              key={key}
                              {...props}
                              className={cx({active: checked}, 'custom-form-check rounded-pill')}
                              type="checkbox"
                              name={key}
                              id={`role.${key}`}
                              label={customLabel(display_name, checked)}
                              {...register(`${key}`, {
                                //onChange: handleClick
                              })}
                              >
                            </Form.Check>
                          </Tooltip>
                        );
                      })}
                    </>
                    );

                  })}
                  </div>
                </Col>
              </Row>
            </Form.Group>
          </Col>
        </Row>
      </Form>
    </div>
  )
}

const PERMISSIONS = gql`
  query getPermissions($filters: GenericFilters) {
    Permissions(filters: $filters) {
      result {
        key
        display_name
        category
        description
      }
    }
  }
`;

const ROLEPERMISSIONS = gql`
  query getRolePermissionMap($filters: GenericFilters) {
    RolePermissions(filters: $filters) {
      result {
        id
        permission_key
        role_id
      }
    }
  }
`;

export default RoleDetails;
