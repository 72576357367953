import {gql, useQuery} from '@apollo/client';

export const useRoleCheck = (username) => {
  let variables = {
    variables: {
      username: username
    }
  };
  const {data, loading} = useQuery(ROLECHECK, variables);
  
  if(!loading) {
    return data?.HasRole === "true";
  }
}

const ROLECHECK = gql`
query roleCheck($username: String!) {
  HasRole(username: $username)
}
`;
