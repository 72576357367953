import PropTypes from 'prop-types';
import {Helmet as Head} from 'react-helmet';
import {Row, Col, Container} from 'react-bootstrap';
import SideNav from '../sideNav';
import stylesheet from '../../css/app.css';
import {ReactComponent as Logo} from '../../assets/portal_logo.svg';
import {useMatch} from 'react-router-dom';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faChevronLeft} from '@fortawesome/pro-light-svg-icons';
import {faCircle} from '@fortawesome/pro-solid-svg-icons';
import {isCollapsed} from '../recoil';
import {useRecoilState} from 'recoil';
import cx from 'classnames';
import {PortalContextProvider} from '../../lib/context/portalContext';
import PageHeader from '../pageHeader';
import 'animate.css';
import {ToastContainer, cssTransition} from "react-toastify";
import UserAgreement from '../user-agreement';
import RefreshToken from '../auth/manualRefreshToken';
//import ExpirationNotice from '../account/expirationNotice';
import SystemMessages from '../system-message';
import {getSessionStore} from '../auth/sessionStore';

export const Main = props => {
  const {children} = props;
  const [collapsed, setCollapsed] = useRecoilState(isCollapsed);
  const handleClick = () => {
    setCollapsed(!collapsed);
  }
  const {ignore} = getSessionStore();

  const customUpDownAnimation = cssTransition({
    enter: "animate__animated animate__fadeInUp",
    exit: "animate__animated animate__fadeOut"
  });
  
  return <>
    <Head>
      <title>Privoro</title>
      <meta charSet='utf-8' />
      <meta name='viewport' content='initial-scale=1.0, width=device-width' />
      <meta httpEquiv="Cache-control" content="no-cache"></meta>
      <style dangerouslySetInnerHTML={{ __html: stylesheet }} />
    </Head>
    <UserAgreement>
      <PortalContextProvider>
        {!ignore && <SystemMessages />}
        <Container fluid data-testid="main-layout">
          <Row className="flex-nowrap">
            <Col xs="auto" id="side-nav-container" className={cx(collapsed && 'collapsed')}>
              <div className="sticky-top" style={{zIndex: 0}}>
                <div className="logo">
                  <Logo data-testid="logo"/>
                </div>
                <SideNav />
                <span data-target="side-nav-container" onClick={handleClick} className={cx('fa-layers fa-fw fa-lg pointer toggle-stack', collapsed && 'collapsed')}>
                  <FontAwesomeIcon icon={faCircle} />
                  <FontAwesomeIcon transform="shrink-6"id="nav-toggle" icon={faChevronLeft} color="black"/>
                </span>
              </div>
            </Col>
            <Col className="main-container">
              <div className="d-block position-relative">
                {/* { !subscriptionsAck && <ExpirationNotice />} */}
                <PageHeader />
                {children}
              </div>
            </Col>
          </Row>
        </Container>
      </PortalContextProvider>
    </UserAgreement>
    <RefreshToken/>
    <ToastContainer theme="dark" transition={customUpDownAnimation}/>
  </>;
};

Main.propTypes = {
  children: PropTypes.node,
};

export const Empty = ({children}) => {
  return children
}

Empty.propTypes = {
  children: PropTypes.node,
};

const Auto = ({children}) => {
  const match = useMatch();

  let Component = (!match || match.path.toLowerCase() === '/login') ? Empty : Main;
  return (<Component children={children}/>)
}

export default Auto;
