import { compose } from 'redux';
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  HttpLink,
  concat,
  from
} from "@apollo/client";
import {onError} from "@apollo/client/link/error";
import {RetryLink} from "@apollo/client/link/retry";
import {setContext} from '@apollo/client/link/context';
import {useRef, useEffect, useState} from 'react';
import {getEnv} from "../../getEnv";
import {endKeycloakSession} from './signOut';
import withAlerts from '../../lib/withAlerts';
import {getSessionStore, getAuthStore} from './sessionStore';

const AuthedApolloProvider = (props) => {
  const {children, alerts: {error}} = props;
  const clientRef = useRef(null);
  const [loaded, setLoaded] = useState(false);
  const {sessionStatus} = getSessionStore();
  
  let env = getEnv();
  
  const networkErrorToast = () => {
    return (
      <>
        <p>We could not process your request due to a network error.</p>
        <p>If this error persists, please reach out to support and we'll help you resolve it.</p>
      </>
    )
  };

  useEffect(() => {
    const retry = new RetryLink({
      delay: {
        initial: 500,
        max: 3000,
        jitter: true
      },
      attempts: {
        max: 2,
        retryIf: (error, _operation) => !!error
      }
    });

    const httpLink = new HttpLink({
      uri: env.graphql_endpoint, // Server URL (must be absolute)
    });

    const link = concat(retry, httpLink);
    
    const setAuth = setContext((request, previousContext) => {
      let {access_token} = getAuthStore();
      let token = access_token || null;

      let headers = {
          headers: {
          ...previousContext.headers,
          authorization: `Bearer ${ token }`
        }
      };
      return headers;
    });

    
    
    clientRef.current = new ApolloClient({
      cache: new InMemoryCache(),
      link: from([errorLink, setAuth.concat(link)]),
      connectToDevTools: process.env.NODE_ENV === 'development',
      defaultOptions: {
        watchQuery: {
          fetchPolicy: 'cache-and-network',
          errorPolicy: 'all',
        },
        query: {
          fetchPolicy: 'cache-and-network',
          errorPolicy: 'all',
        },
        mutate: {
          errorPolicy: 'all',
        },
      }
    });
    setLoaded(true);
  }, [sessionStatus]);

  const errorLink = onError(({ graphQLErrors, networkError, operation, forward }) => {
    if (graphQLErrors) {
      for (let err of graphQLErrors) {
        switch (err.message) {
          case 'No access control specified for attempted operation.':
            //access control error, insufficient permission
            error({msg: err.message});
            //console.log('[p]:', err.message);
            break;

          case 'Unauthorized':
            //insufficient permission
            error({msg: "Insufficient permission to perform operation." ,id: "permsError"});
            //console.log('[p]:', err.message);
            break;
          
          case 'POR-003':
            //authentication error
            console.log('[a]:', err.message);
            endKeycloakSession("authentication error");
            break;

          case 'POR-002':
            //network error
            //networkErrorHandler({title: 'Network Error', msg: networkErrorToast(), id: 'ne-1'});
            console.log('[gn]:', err.message);
            break;
            
          default:
            if(
              err.message.includes("authentication") || 
              err.message.includes("Session error") ||
              err.message.includes("Invalid authorization")
            ) {
              endKeycloakSession("session/auth error");
            }
            console.log('[g]:', err.message);
        }
      }
    }
    if (networkError) {
      console.log('[n]:', networkError);
    }
  })
  
  // wait till we have a client to render
  // todo - render loading component if this is slow enough to be noticable
  
  if(!clientRef.current) { return null }
  
  return (
    <ApolloProvider client={clientRef.current}>
      {children}
    </ApolloProvider>
  )
}

export default compose(withAlerts)(AuthedApolloProvider);
