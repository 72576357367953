import React from 'react';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import RBTooltip from 'react-bootstrap/Tooltip';
import PropTypes from 'prop-types';
import cx from 'classnames';

const Tooltip = props => {
  const {children, content, placement, display, reverse} = props;

  const renderTooltip = (props) => (
    <RBTooltip
      {...props}
      className={cx('custom-tooltip', {reverse: reverse})}
    >
      {content}
    </RBTooltip>
  );

  return (
    <OverlayTrigger
      overlay={renderTooltip}
      trigger={['hover', 'focus']}
      placement={placement}
    >
      <div className={display}>{children}</div>
    </OverlayTrigger>
  );
}

Tooltip.defaultProps = {
  content: '',
  display: 'd-inline-block',
  placement: 'bottom',
  reverse: false
}

Tooltip.propTypes = {
  content: PropTypes.any,
  display: PropTypes.string,
  placement: PropTypes.string,
  reverse: PropTypes.bool,
  children: PropTypes.any
}

export default Tooltip;
