import {useState, useEffect} from 'react';
import {useQuery,gql} from "@apollo/client";
import Table from '../table';
import ActionBar from '../actionBar';
import {dateFormatter} from '../dateFormatter';
import {useRecoilValue } from 'recoil';
import {pageSizeRecoil} from '../recoil';
import Portal from '../portal';
import RoleDetails from './roleDetail';

export const Roles = props => {  
  const [pageNum, setPageNum] = useState(1);
  const [totalRecordsState, setTotalRecordsState] = useState(0);
  const pageSize = useRecoilValue(pageSizeRecoil);
  const {data, loading} = useQuery(ROLES);

  let totalQueried = data?.Roles?.metadata?.page?.records ?? totalRecordsState;

  useEffect(()=> {
    setTotalRecordsState(totalQueried);
  }, [totalQueried]);

  let roles = data?.Roles?.result || [];

  const columns = [
    {
      dataField: 'id',
      hidden: true
    },
    {
      dataField: 'name',
      text: 'Role Name'
    },
    {
      dataField: 'type',
      text: 'Role Type',
      formatter: (cell, row) => {
        if(cell == "S") return "System";
      }
    },
    {
      dataField: 'created_at',
      text: 'Created (UTC)',
      sort: true,
      formatter: dateFormatter
    },
    {
      dataField: 'modified_at',
      text: 'Last Updated (UTC)',
      sort: true,
      formatter: dateFormatter
    }
  ];

  return (
    <>
      <Portal title="Role">
        <RoleDetails />
      </Portal>
     
      <ActionBar showHidden={false} showSearchBar={false} title="Roles" />
      
      <Table
        testId="roles-table"
        columns={columns}
        data={roles}
        loading={loading}
        pageSize={pageSize}
        pageNum={pageNum}
        setPageNum={setPageNum}
        totalRecords={totalRecordsState}
      />
    </>
  );
};

const ROLES = gql`
query getRoles {
  Roles {
    result {
      id
      type
      name
      description
      created_at
      modified_at
    }
    metadata {
      page {
        num
        len
        total
        inPage
        records
      }
    } 
  }
}
`;

export default Roles;
